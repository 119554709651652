import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["sourceOriginInput", "sourceUrlInput", "titleInput"]

    populateSourceUrl(){
      const selectedUrl = this.sourceOriginInputTarget.value === 'custom' ? '' : this.sourceOriginInputTarget.value;selectedUrl;
      this.sourceUrlInputTarget.value = selectedUrl;
    }

    populateTitle(){
      const selectedSource = this.sourceOriginInputTarget.options[this.sourceOriginInputTarget.selectedIndex];
      this.titleInputTarget.value = this.sourceOriginInputTarget.value === 'custom' ? '' : selectedSource.text;
    }
}