import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static targets = ["selectedThemeIcon", "themeDropdownBlock"]
  static values = {
    theme: String,
    lightLogoUrl: String,
    darkLogoUrl: String
  }

  initialize() {
    //We define the future event listener function and the mediaquery here as they will be used for event listener identification and they need to be the exact same for the add/remove event listener to work correctly
    this.darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    this.forceChangeThemeToSystem = event => { this.applyTheme("system") }
  }

  connect() {
    if(!this.themeValue || this.themeValue === "system") this.applyTheme("system");
  }

  async applyTheme(theme) {
    this.darkModeMediaQuery.removeEventListener('change', this.forceChangeThemeToSystem);// Remove the auto-adaptation event listener, preserving the user's explicit theme choice regardless of device settings. 
    
    const systemColorTheme = this.darkModeMediaQuery.matches ? "dark" : "light";
    const themeToApply = theme === "light" || theme === "dark" ? theme : systemColorTheme;
    
    //Disable the transition for all elements with a transition value. This will avoid weird looking transitions of colors when applying the relevant light/dark theme
    const elementsWithTransition = document.querySelectorAll('[class*="transition-"], [class*="collapse-"], [class*="sidebar-"], [class*="sub-"]');

    elementsWithTransition.forEach(element => {
      element.style.setProperty('transition', 'none', 'important');
    });

    this.updateThemeSelectorIcon(theme);
    document.body.setAttribute("data-themes-theme-value", themeToApply);
    this.updateLogo(themeToApply);

    // Wait for next frame to ensure dark/light CSS chagnes are applied
    await new Promise(resolve => requestAnimationFrame(resolve));
    
    // Additional delay just to be sure all the CSS colors and properties for light/dark have been correctly applied
    await new Promise(resolve => setTimeout(resolve, 100));

    // Re-enable the transitions for all elements where we had previously deactivated them so they behave normal again once the system theme has been applied
    elementsWithTransition.forEach(element => {
      element.style.removeProperty('transition');
    });

    if(theme === 'system'){ // If the theme to apply is "system" we add an event listener to automatically adapt the site's theme (without requiring page refresh) when the user changes their device/browser dark mode preference
      this.darkModeMediaQuery.addEventListener('change', this.forceChangeThemeToSystem);
    }
  }

  applySelectedTheme(event){
    event.preventDefault();

    const selectedTheme = event.currentTarget.dataset.themeValue;
    
    //We apply the hover bg-color, opacity and cursor classes to the selected theme to simulate an 'active' status
    this.themeDropdownBlockTargets.forEach(block => block.classList.remove('bg-gray-200', 'opacity-50', 'cursor-not-allowed'));
    event.currentTarget.classList.add('bg-gray-200', 'opacity-50', 'cursor-not-allowed');

    this.updateCustomerThemeSetting(selectedTheme);
    this.applyTheme(selectedTheme);
  }

  updateLogo(theme) {
    const logoImg = document.querySelector("#site-logo");
    if (logoImg) {
      const logoSrc = theme === 'dark'
        ? this.lightLogoUrlValue
        : this.darkLogoUrlValue;
      logoImg.src = logoSrc;
    }
  }

  updateThemeSelectorIcon(theme){
    const selectedIcon = this.selectedThemeIconTargets.find(icon => icon.classList.contains(theme));

    if(selectedIcon){
      this.selectedThemeIconTargets.forEach(icon => icon.classList.add('hide'));
      selectedIcon.classList.remove('hide');
    }
  }

  async updateCustomerThemeSetting(theme) {
    const request = new FetchRequest("patch", "/customer/themes", {
      body: {
        customer: {
          theme_preference: theme
        }
      },
      responseKind: "json"
    })

    await request.perform();
  }
}